const useTableStyles = (props) => {
  return {
    h: 'auto',
    minWidth: '500px',
    zIndex: 0,
    position: 'relative',
    w: '100%',
    flexGrow: 1
  }
}

const cellSizes = {
  lg: {
    px: '10px',
    py: '15px',
    fontSize: 'lg'
  },
  md: {
    px: '10px',
    py: '8px',
    fontSize: 'md'
  },
  sm: {
    px: '10px',
    py: '6px',
    fontSize: 'md'
  }
}

const tableRowStyles = (props) => {
  return {
    fontWeight: props.isSelected && 'semibold',
    bg: props.isSelected ? 'tables.rowSelectedBg' : 'transparent',
    transition: 'all 0.3s ease'
  }
}

const tableCellStyles = (props) => {
  return {
    bg: 'transparent',
    ...cellSizes[props.size],
    textOverflow: 'ellipsis',
    boxSizing: 'border-box',
    position: 'relative',
    borderBottom: '1px',
    borderColor: 'tables.borderColour',
    overflowX: 'hidden',
    whiteSpace: 'wrap',
    display: 'flex',
    alignItems: 'center'
  }
}

const headCellSizes = {
  lg: {
    px: '10px',
    py: '15px',
    fontSize: 'lg'
  },
  md: {
    px: '10px',
    py: '6px',
    fontSize: 'md'
  },
  sm: {
    px: '10px',
    py: '6px',
    fontSize: 'sm'
  }
}

const tableHeadCellStyles = (props) => {
  return {
    color: props.isSorted ? 'primary' : 'tables.headerColour',
    fontWeight: 'semibold',
    borderBottom: props.isBordered ? '2px' : 0,
    flexShrink: 0,
    bg: 'global.elementBg',
    borderColor: props.isSorted ? 'primary' : 'tables.borderColour',
    ...headCellSizes[props.size],
    fontSize: 'md',
    position: 'sticky',
    top: '0px'
  }
}

export { useTableStyles, tableCellStyles, tableHeadCellStyles, tableRowStyles }
