/** @jsx jsx */
import { jsx } from '@emotion/react'
import { forwardRef } from 'react'
import Box from '../Box'
import PropTypes from 'prop-types'
import useTagStyle from './styles'
import { omitProps } from '../utils/omitProps'

const Tag = forwardRef((props, ref) => {
  const { variant, children, variantColor, isRounded } = props
  const sizes = {
    lg: {
      height: 10,
      fontSize: 'lg',
      px: 6,
      borderRadius: isRounded ? 'full' : '7px'
    },
    md: {
      height: 8,
      fontSize: 'md',
      px: 4,
      borderRadius: isRounded ? 'full' : '4px'
    },
    sm: {
      height: 6,
      fontSize: 'sm',
      px: 3,
      borderRadius: isRounded ? 'full' : '3px'
    },
    xs: {
      height: 4,
      fontSize: 'xs',
      px: 2,
      borderRadius: isRounded ? 'full' : '3px'
    }
  }

  const tagStyles = useTagStyle({ variant, color: variantColor })

  return (
    <Box
      ref={ref}
      as='div'
      {...tagStyles}
      {...sizes[props.size]}
      w='auto'
      display='inline-flex'
      fontWeight='semibold'
      _hover={{ cursor: 'default' }}
      {...omitProps(['size'], props)}
    >
      {children}
    </Box>
  )
})

Tag.propTypes = {
  /**
   * The element type you wish to render
   */
  as: PropTypes.string,
  /**
   * The variant type of the button
   */
  variant: PropTypes.oneOf(['solid', 'subtle', 'plain']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Colour theme of the button - can relate to colours in your theme colour object
   */
  variantColor: PropTypes.oneOfType([
    PropTypes.oneOf(['silver', 'primary', 'red', 'green']),
    PropTypes.string
  ]),
  /**
   * Rounded corners
   */
  isRounded: PropTypes.bool
}

Tag.defaultProps = {
  size: 'sm',
  variant: 'subtle',
  variantColor: 'error'
}

export default Tag
