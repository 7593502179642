/** @jsx jsx */
import { jsx } from '@emotion/react'
import { forwardRef } from 'react'
import Flex from '../Flex'
import Box from '../Box'
import { useFormControl } from '../FormControl'
import { useColorMode } from '../ColourModeProvider'

export const RequiredIndicator = (props) => {
  const { colorMode } = useColorMode()
  const color = { light: 'gray.200', dark: 'gray.700' }
  return (
    <Box
      as='span'
      ml='auto'
      bg={color[colorMode]}
      aria-hidden='true'
      children='Required'
      rounded='md'
      fontSize='xs'
      px='5px'
      fontWeight='light'
      {...props}
    />
  )
}

export const FormLabel = forwardRef(
  ({ children, isRequired, isDisabled, ...props }, ref) => {
    const formControl = useFormControl(props)
    return (
      <Flex
        ref={ref}
        fontSize='sm'
        pb='4px'
        opacity={isDisabled ? '0.4' : '0.8'}
        fontWeight='medium'
        textAlign='left'
        verticalAlign='middle'
        w='100%'
        as='label'
        whiteSpace='nowrap'
        {...props}
      >
        {children}
        {formControl.isRequired && <RequiredIndicator />}
      </Flex>
    )
  }
)

FormLabel.displayName = 'FormLabel'

export default FormLabel
