import React, { forwardRef } from 'react'
import Box from '../Box'
import PropTypes from 'prop-types'

const Flex = forwardRef(({ align, justify, wrap, direction, ...rest }, ref) => (
  <Box
    ref={ref}
    display='flex'
    flexDirection={direction}
    alignItems={align}
    justifyContent={justify}
    flexWrap={wrap}
    {...rest}
  />
))

Flex.displayName = 'Flex'

Flex.propTypes = {
  /**
   * Shorthand for justify-content
   */
  justify: PropTypes.oneOf([
    'center',
    'flex-start',
    'flex-end',
    'space-around',
    'space-between',
    'stretch'
  ]),
  /**
   * Shorthand for align-items.
   */
  align: PropTypes.oneOfType([
    PropTypes.oneOf(['center', 'flex-start', 'flex-end']),
    PropTypes.object
  ]),

  /**
   * Shorthand for flex-wrap
   */
  wrap: PropTypes.oneOf(['wrap', 'nowrap', 'wrap-reverse']),
  /**
   * Shorthand for flex-direction
   */
  direction: PropTypes.oneOf(['row', 'column'])
}

Flex.defaultProps = {
  direction: 'row',
  wrap: 'nowrap'
}

export default Flex
