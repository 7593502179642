/** @jsx jsx */
import { jsx } from '@emotion/react'
import { ToastProvider, useToasts } from 'react-toast-notifications'
import { useCallback, memo } from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated } from 'react-spring'
import Alert from '../Alert'
import Box from '../Box'
import CloseButton from '../CloseButton'

const Toast = memo(
  ({
    status,
    variant,
    id,
    title,
    isClosable,
    onClose,
    message,
    onDismiss,
    children,
    ...props
  }) => {
    const animation = useSpring({
      opacity: 1,
      transform: 'translateX(0px)',
      zIndex: 9999,
      from: { opacity: 0, zIndex: 9999, transform: 'translateX(100px)' }
    })
    return (
      <animated.div style={animation}>
        <Alert
          status={children.status}
          variant={children.variant}
          id={id}
          textAlign='left'
          boxShadow='toast'
          rounded='toast'
          alignItems='start'
          m={2}
          pr={8}
          title={children.title}
          {...props}
        >
          <Box flex='1'>{children.message}</Box>
          {children.isClosable && (
            <CloseButton
              size='xs'
              rounded='lg'
              onClick={onDismiss}
              position='absolute'
              right='4px'
              top='4px'
              bg='blackAlpha.200'
              _hover={{
                bg: 'whiteAlpha.300'
              }}
            />
          )}
        </Alert>
      </animated.div>
    )
  }
)

function useToast() {
  const { addToast } = useToasts()

  const notify = useCallback(
    ({
      title,
      message,
      status = 'success',
      variant,
      isClosable = true,
      position
    }) => {
      addToast(
        { title, message, variant, status, isClosable },
        { appearance: 'success', autoDismiss: true, placement: position }
      )
    },
    [addToast]
  )

  return notify
}

Toast.propTypes = {
  /**
   * The status of the toast.
   */
  status: PropTypes.oneOf(['success', 'info', 'error', 'warning']),
  /**
   * The position of the toast.
   */
  position: PropTypes.oneOf([
    'top-left',
    'top',
    'top-right',
    'bottom-left',
    'bottom',
    'bottom-right'
  ]),
  /**
   * The status of the toast.
   */
  isClosable: PropTypes.bool,
  /**
   * The title of the toast.
   */
  title: PropTypes.string,
  /**
   * The message body of the toast.
   */
  message: PropTypes.string,
  /**
   * The time in milliseconds to show the toast (use null to keep it open until closed manually)
   */
  duration: PropTypes.number,
  /**
   * The style variant of the toast
   */
  variant: PropTypes.oneOf(['solid', 'subtle', 'plain'])
}

export { useToast, Toast }

export const ToastWrapper = ({ children }) => {
  return <ToastProvider components={{ Toast: Toast }}>{children}</ToastProvider>
}
