import global from "./global";
import {base} from '../palette'

export default {
  light: {
    background: global.light.elementBg,
    unSelectedBackground: "transparent",
    hoverBackground: base.gray[200]
  },
  dark: {
    background: global.dark.elementBg,
    unSelectedBackground: "transparent",
    hoverBackground: base.gray[700]
  },
};
