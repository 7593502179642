import { addOpacity, generateAlphaColors } from '../theme/colors-utils'
import { useColorMode, useTheme } from '../ColourModeProvider'

const solidStyle = ({ theme: { colors }, color }) => {
  const _color = colors[`${color}`]
  const darkModeBg = addOpacity(_color, 0.6)
  return {
    light: {
      bg: _color,
      color: 'white'
    },
    dark: {
      bg: darkModeBg,
      color: 'whiteAlpha.800'
    }
  }
}

const subtleStyle = ({ theme: { colors }, color }) => {
  const _color = colors[`${color}`]
  const alphaColors = generateAlphaColors(_color)

  const darkModeBg = alphaColors[300]
  return {
    light: {
      bg: alphaColors[200],
      color: _color
    },
    dark: {
      bg: darkModeBg,
      color: _color
    }
  }
}

const outlineStyle = ({ theme: { colors }, color }) => {
  const _color = colors[`${color}`]
  const darkModeColor = addOpacity(_color, 0.8)
  const boxShadowColor = colors[`${color}`]
  return {
    light: {
      boxShadow: `inset 0 0 0px 1px ` + boxShadowColor,
      color: _color
    },
    dark: {
      boxShadow: `inset 0 0 0px 1px ` + darkModeColor,
      color: darkModeColor
    }
  }
}

const plainStyle = ({ theme: { colors }, color }) => {
  const _color = colors[`${color}`]
  const darkModeColor = addOpacity(_color, 0.8)

  return {
    light: {
      paddingLeft: '0px',
      paddingRight: '0px',
      boxShadow: '0px',
      color: _color
    },
    dark: {
      paddingLeft: '0px',
      paddingRight: '0px',
      boxShadow: '0px',
      color: darkModeColor
    }
  }
}

const variantProps = (props) => {
  const { variant, colorMode } = props
  switch (variant) {
    case 'solid':
      return solidStyle(props)[colorMode]
    case 'subtle':
      return subtleStyle(props)[colorMode]
    case 'outline':
      return outlineStyle(props)[colorMode]
    case 'plain':
      return plainStyle(props)[colorMode]
    default:
      return {}
  }
}

const useBadgeStyle = (props) => {
  const theme = useTheme()
  const { colorMode } = useColorMode()
  const _props = { ...props, theme, colorMode }

  return variantProps(_props)
}

export default useBadgeStyle
