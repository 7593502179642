import React from 'react'
import Box from '../Box'
import Flex from '../Flex'
import PropTypes from 'prop-types'

const Progress = (props) => {
  const { value, width, height, bg, children } = props

  const pos = 100 - value

  return (
    <Box
      w='100%'
      h={height ? height : '5px'}
      position='relative'
      overflow='hidden'
      rounded='full'
    >
      {children && (
        <Flex
          align='center'
          position='absolute'
          left={0}
          w='100%'
          top={0}
          h='100%'
          zIndex={5}
        >
          {children}
        </Flex>
      )}
      <Box
        position='absolute'
        left={`-${pos}%`}
        w={width ? width : '100%'}
        transition='left 1s ease-in-out'
        content="' '"
        h='inherit'
        bg={bg ? bg : 'primary'}
        rounded='full'
        zIndex={4}
      ></Box>
    </Box>
  )
}

Progress.propTypes = {
  /**
   * The placement of the dropdown in relation to the trigger element
   */
  value: PropTypes.number
}

export default Progress
