/** @jsx jsx */
import { jsx } from '@emotion/react'
import { forwardRef } from 'react'
import Flex from '../Flex'
import Box from '../Box'
import { useColorMode, useTheme } from '../ColourModeProvider'
import ControlBox from '../ControlBox'
import VisuallyHidden from '../VisuallyHidden'
import PropTypes from 'prop-types'
import { isDarkColor } from '../theme/colors-utils'
import Text from '../Text'

const switchSizes = {
  sm: {
    width: '3.2rem',
    height: '1.45rem'
  },
  md: {
    width: '3.275rem',
    height: '1.5rem'
  },
  lg: {
    width: '4.175rem',
    height: '2.1rem'
  }
}

const Switch = forwardRef(
  (
    {
      id,
      name,
      value,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      color,
      isChecked,
      size,
      isDisabled,
      isInvalid,
      onChange,
      onBlur,
      onFocus,
      children,
      ...rest
    },
    ref
  ) => {
    const { colorMode } = useColorMode()
    const theme = useTheme()
    const width = switchSizes[size] && switchSizes[size]['width']
    const height = switchSizes[size] && switchSizes[size]['height']

    const stylesProps = {
      rounded: 'full',
      position: 'relative',
      overflow: 'hidden',
      justifyContent: 'flex-start',
      width,
      height,
      bg: colorMode === 'dark' ? 'whiteAlpha.400' : 'gray.300',
      boxSizing: 'content-box',
      boxShadow: 'rgb(6 22 33 / 12%) 0px 0px 5px inset',
      mr: '10px',

      p: '2px',
      _checked: {
        bg: isDarkColor(theme.colors[color]) ? '#25CB72' : '#27D592'
      },
      _child: {
        transform: `translateX(0)`
      },
      _checkedAndChild: {
        transform: `translateX(calc(${width} - ${height}))`
      },
      _focus: {
        boxShadow: 'none'
      },
      _hover: {
        cursor: 'pointer'
      },
      _checkedAndHover: {
        cursor: 'pointer'
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed'
      }
    }

    return (
      <Flex as='label' display='inline-flex' align='center' {...rest}>
        <VisuallyHidden
          as='input'
          type='checkbox'
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          id={id}
          ref={ref}
          name={name}
          value={value}
          aria-invalid={isInvalid}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          checked={isChecked}
          disabled={isDisabled}
        />
        <ControlBox {...stylesProps}>
          <Text
            fontWeight='500'
            pos='absolute'
            left={`-18px`}
            fontSize='9px'
            color='white'
            zIndex={500}
          >
            ON
          </Text>
          <Box
            backgroundImage='linear-gradient(rgb(255, 255, 255), rgb(246, 246, 246))'
            transition='transform 250ms'
            rounded='full'
            size={height}
            boxShadow='rgb(28 192 97 / 12%) 0px 0px 2px, rgb(0 0 0 / 27%) 0px 1px 2px, rgb(241 241 241) 0px -1px 0px inset'
          />
          <Text
            fontWeight='500'
            pos='absolute'
            right='5px'
            fontSize='9px'
            opacity={0.6}
          >
            OFF
          </Text>
        </ControlBox>
        <Box as='span' fontSize={size}>
          {children}
        </Box>
      </Flex>
    )
  }
)

Switch.displayName = 'Switch'

Switch.defaultProps = {
  color: 'primary',
  size: 'md'
}

Switch.propTypes = {
  /**
   * The size of the switch
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  /**
   * The size of the switch
   */
  value: PropTypes.bool,
  /**
   * Set the checked state
   */
  isChecked: PropTypes.bool,
  /**
   * Set the disabled state
   */
  isDisabled: PropTypes.bool,
  /**
   * Set the background colour
   */
  color: PropTypes.string
}

export default Switch
