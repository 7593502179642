/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useEffect, useState, forwardRef, useRef } from 'react'
import Box from '../Box'
import PropTypes from 'prop-types'

export function useHasImageLoaded(props) {
  const { src, onLoad, onError, enabled = true } = props
  const isMounted = useRef(true)
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (!src || !enabled) {
      return
    }

    const image = new window.Image()
    image.src = src

    image.onload = (event) => {
      if (isMounted.current) {
        setHasLoaded(true)
        onLoad && onLoad(event)
      }
    }

    image.onerror = (event) => {
      if (isMounted.current) {
        setHasLoaded(false)
        onError && onError(event)
      }
    }
  }, [src, onLoad, onError, enabled])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return hasLoaded
}

const NativeImage = forwardRef(
  ({ htmlWidth, htmlHeight, alt, ...props }, ref) => (
    <img width={htmlWidth} height={htmlHeight} ref={ref} alt={alt} {...props} />
  )
)

const Image = forwardRef((props, ref) => {
  const {
    src,
    fallbackSrc,
    onError,
    onLoad,
    ignoreFallback,

    ...rest
  } = props

  const hasLoaded = useHasImageLoaded({
    src,
    onLoad,
    onError,
    enabled: !Boolean(ignoreFallback)
  })

  const imageProps = ignoreFallback
    ? { src, onLoad, onError }
    : { src: hasLoaded ? src : fallbackSrc }

  return <Box as={NativeImage} ref={ref} {...imageProps} {...rest} />
})

Image.displayName = 'Image'

Image.propTypes = {
  /**
   * The path to the image source
   */
  src: PropTypes.string,
  /**
   * In event there was an error loading the src, specify a fallback. In most cases, this can be an avatar or image placeholder
   */
  fallbackSrc: PropTypes.string,
  /**
   * The alt text that describes the image
   */
  alt: PropTypes.string,
  /**
   * A callback for when the image src has been loaded
   */
  onLoad: PropTypes.func,
  /**
   * A callback for when there was an error loading the image src
   */
  onError: PropTypes.string,
  /**
   * The native HTML width attribute of the img element
   */
  htmlWidth: PropTypes.string,
  /**
   * The native HTML height attribute of the img element
   */
  htmlHeight: PropTypes.string,
  /**
   * Opt out of the fallbackSrc logic and use the Image directly
   */
  ignoreCallback: PropTypes.bool
}

export default Image
