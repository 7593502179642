import React from 'react'
import Box from '../Box'
import Text from '../Text'
import PropTypes from 'prop-types'

const Card = (props) => {
  const { children, title, ...rest } = props

  const defaultStyle = {
    bg: 'global.elementBg',
    boxShadow: 'card',
    borderRadius: 'card',
    w: '100%',
    h: 'auto',
    display: 'block'
  }

  return (
    <Box bg='global.elementBg' {...defaultStyle} {...rest}>
      {title && (
        <Text
          fontSize='lg'
          fontWeight='medium'
          px={4}
          py={3}
          borderBottom='1px solid'
          borderColor='blackAlpha.200'
        >
          {title}
        </Text>
      )}
      {children}
    </Box>
  )
}

Card.defaultProps = {
  bg: 'global.elementBg'
}

Card.displayName = 'Card'

Card.propTypes = {
  /**
   * The element type you wish to render
   */
  title: PropTypes.string
}

export default Card
