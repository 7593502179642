import React, { forwardRef } from 'react'
import InputNumber from 'rc-input-number'
import Flex from '../Flex'
import Input from '../Input'
import IconButton from '../IconButton'
import Box from '../Box'
import styled from '@emotion/styled'
import { BsPlus } from 'react-icons/bs'
import { FiMinus } from 'react-icons/fi'
import PropTypes from 'prop-types'

const PlasmaInput = styled(InputNumber)`
  input {
    display: none;
  }
  .plasma-input-number-handler-up {
    right: 0;
    position: absolute;
  }
  .plasma-input-number-handler-down {
    left: 0;
    position: absolute;
  }
`

const DefaultUpHandler = (props) => (
  <IconButton
    icon={<BsPlus />}
    {...props.actionComponentProps}
    size={props.size}
  />
)
const DefaultDownHandler = (props) => (
  <IconButton
    icon={<FiMinus />}
    {...props.actionComponentProps}
    size={props.size}
  />
)

const NumberInput = forwardRef((props, ref) => {
  const {
    min, // Minimum value
    max, // Max value
    onClick, // onClick handler
    step, // The step size
    precision, // Specifies the precision length of value
    disabled,
    readOnly,
    name,
    id,
    value,
    defaultValue,
    onChange,
    onPressEnter,
    onFocus,
    upHandler,
    downHandler,
    formatter, // Specifies the format of the value presented --- (value: number|string): displayValue: string
    parser, // Specifies the value extracted from formatter  ---  (displayValue: string) => value: number
    pattern, // Specifies a regex pattern to be added to the input number element - useful for forcing iOS to open the number pad instead of the normal keyboard (supply a regex of "\d*" to do this) or form validation
    decimalSeparator,
    actionComponentProps,
    ...rest
  } = props

  const [inputValue, setValue] = React.useState(defaultValue)

  const handleChange = (val) => {
    setValue(val)
    onChange(val)
  }

  React.useEffect(() => {
    if (value) {
      setValue(value)
    }
  }, [value])

  return (
    <Flex
      w='100%'
      position='relative'
      px={props.size === 'lg' ? '50px' : '42px'}
    >
      <Input
        value={inputValue}
        onChange={(e) => setValue(e.target.value)}
        {...rest}
      />

      <Box position='absolute' w='100%' left={0}>
        <PlasmaInput
          defaultValue={defaultValue}
          min={min}
          max={max}
          onClick={onClick}
          step={step}
          precision={precision}
          disabled={disabled}
          readOnly={readOnly}
          name={name}
          id={id}
          value={inputValue}
          onChange={(val) => handleChange(val)}
          onPressEnter={onPressEnter}
          onFocus={onFocus}
          upHandler={
            upHandler ? (
              upHandler
            ) : (
              <DefaultUpHandler
                size={props.size}
                actionComponentProps={actionComponentProps}
              />
            )
          }
          downHandler={
            downHandler ? (
              downHandler
            ) : (
              <DefaultDownHandler
                size={props.size}
                actionComponentProps={actionComponentProps}
              />
            )
          }
          formatter={formatter}
          parser={parser}
          pattern={pattern}
          decimalSeparator={decimalSeparator}
          prefixCls='plasma-input-number'
        />
      </Box>
    </Flex>
  )
})

NumberInput.propTypes = {
  /**
   * The variant type of the input
   */
  variant: PropTypes.oneOf(['outline', 'filled', 'unstyled', 'flushed']),
  /**
   * The size of the input
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  /**
   * The increment / decrement size
   */
  step: PropTypes.number,
  /**
   * Number of decimal places
   */
  precision: PropTypes.number,
  /**
   * The min value
   */
  min: PropTypes.number,
  /**
   * The max value
   */
  max: PropTypes.number,
  /**
   * The onChange function (value) =>
   */
  onChange: PropTypes.func,
  /**
   * The input value =>
   */
  value: PropTypes.number,
  /**
   * The default starting  value =>
   */
  defaultValue: PropTypes.number,
  /**
   * The component for the up button =>
   */
  upHandler: PropTypes.node,
  /**
   * The component for the down button =>
   */
  downHandler: PropTypes.node
}

NumberInput.defaultProps = {
  defaultValue: 1,
  step: 1,
  max: null,
  min: null,
  precision: 1,
  value: null,
  onChange: (value) => console.log(value),
  variant: 'outline',
  size: 'lg',
  upHandler: null,
  downHandler: null,
  actionComponentProps: {
    variant: 'outline'
  }
}

NumberInput.displayName = 'NumberInput'

export default NumberInput
