import React from 'react'
import childrenWithProps from '../utils/childrenWithProps'
import PseudoBox from '../PseudoBox'
import Box from '../Box'
import {
  Tabs as UITabs,
  TabList as UITabList,
  Tab as UITab,
  TabPanels as UITabPanels,
  TabPanel as UITabPanel
} from '@reach/tabs'
import PropTypes from 'prop-types'

import { useTabStyle, useHoverStyle } from './styles'

export const Tabs = (props) => {
  const {
    orientation,
    variant,
    size,
    children,
    index = null,
    defaultIndex = null,
    style,
    ...rest
  } = props

  return (
    <UITabs
      orientation={orientation}
      index={index}
      default={defaultIndex}
      style={{ ...style }}
    >
      <PseudoBox
        display={orientation === 'vertical' ? 'flex' : 'block'}
        {...rest}
      >
        {childrenWithProps(children, { orientation, variant, size })}
      </PseudoBox>
    </UITabs>
  )
}
Tabs.defaultProps = {
  orientation: 'vertical',
  size: 'md',
  variant: 'underlined'
}

Tabs.propTypes = {
  /**
   * The orientation of the tabs
   */
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  /**
   * The variant of the tabs
   */
  variant: PropTypes.oneOf(['underlined', 'tab']),
  /**
   * The size of the tabs
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  /**
   * The index of of the selected tab
   */
  index: PropTypes.number,
  /**
   * The default index of of the selected tab
   */
  defaultIndex: PropTypes.number
}

export const TabList = (props) => {
  const { children, orientation, variant, size, style, ...rest } = props
  return (
    <Box w='auto' ml='-5px' overflowX='scroll' flexShrink={0}>
      <UITabList
        style={{
          background: 'transparent',
          flexShrink: 0,
          display: 'flex',
          flexWrap: 'nowrap',
          flexDirection: orientation === 'vertical' ? 'column' : 'row',
          ...style
        }}
      >
        {childrenWithProps(children, {
          orientation,
          variant,
          size,
          ...rest
        })}
      </UITabList>
    </Box>
  )
}

export const Tab = (props) => {
  const {
    children,
    disabled,
    orientation,
    isSelected,
    selectedStyle,
    variant,
    size,
    style,
    ...rest
  } = props

  const sizes = {
    lg: { px: orientation === 'vertical' ? 5 : 10, py: 3, fontSize: 'lg' },
    md: {
      px: orientation === 'vertical' ? 3 : 5,
      py: 2,
      fontSize: 'md'
    },
    sm: {
      px: orientation === 'vertical' ? 2 : 4,
      py: 2,
      fontSize: 'sm'
    }
  }

  return (
    <UITab
      disabled={disabled}
      {...props}
      style={{
        padding: 0,
        border: 0,
        outline: 0,
        marginLeft: orientation === 'vertical' ? 0 : 5,
        flexShrink: 0,
        ...style
      }}
    >
      <PseudoBox
        {...useTabStyle({
          variant,
          orientation,
          isSelected,
          selectedStyle,
          size
        })}
        {...rest}
      >
        <PseudoBox
          {...sizes[size]}
          _hover={useHoverStyle(isSelected)}
          w='100%'
          display='flex'
          alignItems='center'
          flexWrap='nowrap'
          flexShrink={0}
          textAlign='center'
          h='100%'
          {...rest}
        >
          {children}
        </PseudoBox>
      </PseudoBox>
    </UITab>
  )
}

export const TabPanels = (props) => {
  const { children, style } = props

  return (
    <UITabPanels style={{ width: '100%', ...style }}>{children}</UITabPanels>
  )
}

export const TabPanel = (props) => {
  const { children, style } = props

  return (
    <UITabPanel style={{ border: 'none', outline: 'none', ...style }}>
      {children}
    </UITabPanel>
  )
}
