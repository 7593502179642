import { Children, isValidElement, cloneElement } from 'react';

const childrenWithProps = (children, props) =>
    Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, props);
        }
        return child;
    });

export default childrenWithProps;
