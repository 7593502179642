// https://smart-swatch.netlify.app/

import { base } from './palette'
import global from './components/global'
import inputs from './components/inputs'
import tooltip from './components/tooltip'
import tabs from './components/tabs'
import buttons from './components/buttons'
import tables from './components/tables'
import popover from './components/popover'
import toast from './components/toast'

const intents = {
  light: {
    warning: base.orange[400],
    success: '#03bda1',
    error: base.red[400],
    info: '#0065ff'
  },
  dark: {
    warning: base.orange[400],
    success: '#03bda1',
    error: '#FF606C',
    info: '#0065ff'
  }
}

const colours = {
  ...base,
  ...intents.light,
  transparent: 'transparent',
  current: 'currentColor',
  black: base.gray[900],
  silver: base.gray[400],
  white: '#fff',
  primary: '#0065ff',
  green: '#03bda1',
  pink: '#DF0099',
  docsCodeBlock: '#011627',
  red: base.red[400],
  blue: base.blue[400],
  teal: { ...base.teal },
  orange: base.orange[500],
  global: { ...global.light },
  inputs: { ...inputs.light },
  error: base.red[500],
  borderColour: base.gray[300],
  modalBackdrop: 'rgba(0,0,0,0.6)',
  scrollElement: 'rgba(0,0,0,0.95)',
  tooltip: {
    ...tooltip.light
  },
  tabs: {
    ...tabs.light
  },
  buttons: {
    ...buttons.light
  },
  popover: {
    ...popover.light
  },
  tables: { ...tables.light },
  toast: { ...toast.light },
  modes: {
    dark: {
      ...intents.dark,
      borderColour: base.whiteAlpha[200],
      primary: '#2d88ff',
      docsCodeBlock: '#1a202c',
      modalBackdrop: 'rgba(0,0,0,0.8)',
      scrollElement: 'rgba(255,255,255,0.7)',
      global: { ...global.dark },
      inputs: { ...inputs.dark },
      tooltip: {
        ...tooltip.dark
      },
      tabs: { ...tabs.dark },
      buttons: {
        ...buttons.dark
      },
      popover: {
        ...popover.dark
      },
      tables: { ...tables.dark },
      toast: { ...toast.dark }
    }
  }
}

export default colours
