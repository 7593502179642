import { base } from "../palette";

export default {
  light: {
    background: base.blackAlpha[800],
  },
  dark: {
    background: base.whiteAlpha[300],
  },
};
