import { useColorMode, useTheme } from "../ColourModeProvider";

/**
|--------------------------------------------------
| Styles for MenuItem
|--------------------------------------------------
*/

const baseProps = {
  width: "full",
  display: "flex",
  alignItems: "center",
  userSelect: "none",
  transition: "background-color 220ms, color 220ms",
  color: "currentColor",
  px: "15px",
  py: "9px",
};

const interactionProps = ({ colorMode }) => {
  const _focusColor = { light: "gray.100", dark: "whiteAlpha.100" };
  const _activeColor = { light: "blackAlpha.100", dark: "blackAlpha.400" };

  return {
    _active: {
      bg: _activeColor[colorMode],
    },
    _focus: {
      bg: _focusColor[colorMode],
      outline: 0,
    },
    _hover: {
      bg: _activeColor[colorMode],
      color: "currentColor",
      cursor: "pointer",
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed",
    },
  };
};

export const useMenuItemStyle = () => {
  const theme = useTheme();
  const { colorMode } = useColorMode();
  const props = { theme, colorMode };

  return {
    ...baseProps,
    ...interactionProps(props),
  };
};
