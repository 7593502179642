import React from 'react';
import Box from '../Box';
import Flex from '../Flex';
import { isDarkColor } from '../theme/colors-utils';
import PropTypes from 'prop-types';
import { MdRadioButtonUnchecked, MdRadioButtonChecked } from 'react-icons/md';
import Icon from '../Icon';
import { default as MuiRadio } from '@material-ui/core/Radio';
import { default as MuiRadioGroup } from '@material-ui/core/RadioGroup';
import { useTheme } from '../ColourModeProvider';

const RadioGroup = (props) => {
    return <MuiRadioGroup {...props}>{props.children}</MuiRadioGroup>;
};

RadioGroup.propTypes = {
    /**
     * The children
     */
    children: PropTypes.node,
    /**
     * The default input element value. Use when the component is not controlled.
     */
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * The name used to reference the value of the control. If you don't provide this prop, it falls back to a randomly generated name.
     */
    name: PropTypes.string,
    /**
     * Callback fired when a radio button is selected.
     */
    onChange: PropTypes.func,

    /**
     * Value of the selected radio button. The DOM API casts this to a string.
     */
    value: PropTypes.string,
};

const sizes = {
    lg: {
        fontSize: 30,
    },
    md: {
        fontSize: 24,
    },
    sm: {
        fontSize: 18,
    },
};

const Radio = ({
    size,
    onChange,
    checked,
    isDisabled,
    isRequired,
    name,
    value,
    label,
    color,
}) => {
    const RadioComponent = (props) => {
        const theme = useTheme();

        const handleChange = (e) => {
            if (onChange) {
                onChange(e);
            }
        };

        return (
            <MuiRadio
                color='default'
                onChange={handleChange}
                required={isRequired}
                checked={checked}
                name={name}
                value={value}
                checkedIcon={
                    <Icon
                        {...sizes[size]}
                        color={
                            color
                                ? isDarkColor(theme.colors[color])
                                    ? color
                                    : 'blackAlpha.800'
                                : 'primary'
                        }
                        opacity={isDisabled ? 0.5 : 1}
                    >
                        <MdRadioButtonChecked />
                    </Icon>
                }
                icon={
                    <Icon {...sizes[size]} color='inputs.borderColour'>
                        <MdRadioButtonUnchecked />
                    </Icon>
                }
                {...props}
            />
        );
    };

    return (
        <>
            <Flex
                as='label'
                align='center'
                justify='flex-start'
                cursor={isDisabled ? 'not-allowed' : 'pointer'}
            >
                <RadioComponent value={value} />
                <Box fontSize={size} as='span' opacity={isDisabled ? 0.4 : 1}>
                    {label}
                </Box>
            </Flex>
        </>
    );
};

Radio.defaultProps = {
    size: 'md',
    checked: false,
    defaultChecked: false,
    isDisabled: false,
    name: '',
    value: '',
};

Radio.propTypes = {
    /**
     * The size of the radio
     */
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    /**
     * If true, the component is checked.
     */
    checked: PropTypes.bool,
    /**
     * The color of the component. It supports theme colors
     */
    color: PropTypes.string,
    /**
   * Callback fired when the state is changed. function(event: object) => void
event: The event source of the callback. You can pull out the new value by accessing event.target.value (string). You can pull out the new checked state by accessing event.target.checked (boolean).
   */
    onChange: PropTypes.func,
    /**
     * The disabled state
     */
    isDisabled: PropTypes.bool,
    /**
     * The required state
     */
    isRequired: PropTypes.bool,
    /**
     * The input name
     */
    name: PropTypes.string,
    /**
     * The input value
     */
    value: PropTypes.string,
};

export { Radio, RadioGroup };
