import global from './global'
import { base } from '../palette'

export default {
  light: {
    background: global.light.elementBg,
    borderColour: base.gray[200],
    headerBg: base.gray[100],
    headerColour: base.gray[900],
    rowHoverBg: base.gray[100],
    rowSelectedBg: base.gray[300]
  },
  dark: {
    background: global.dark.elementBg,
    borderColour: base.whiteAlpha[300],
    headerBg: base.blackAlpha[400],
    headerColour: base.whiteAlpha[800],
    rowHoverBg: base.whiteAlpha[50],
    rowSelectedBg: base.whiteAlpha[300]
  }
}
