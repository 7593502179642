/** @jsx jsx */
import React, { createRef } from 'react'
import { jsx } from '@emotion/react'
import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import useInputStyle from '../Input/styles'
import Input from '../Input'
import autosize from 'autosize'
import { useTheme } from '../ColourModeProvider'

const TextArea = forwardRef((props, ref) => {
  const theme = useTheme()
  const textAreaRef = createRef()

  const styles = useInputStyle(props)

  React.useEffect(() => {
    autosize(textAreaRef.current)
  }, [textAreaRef])

  React.useEffect(() => {
    autosize.update(textAreaRef.current)
  }, [props.value, props.defaultValue, textAreaRef])

  return (
    <Input
      as='textarea'
      ref={textAreaRef}
      {...styles}
      overflow='hidden'
      d='block'
      transition='none'
      h='auto'
      py='5px'
      w='100%'
      _focus={{
        border: theme.borders.input,
        borderColor: theme.colors.primary,
        bg: props.variant === 'filled' && 'transparent'
      }}
      transform='translateZ(0px)'
      {...props}
    />
  )
})

TextArea.propTypes = {
  /**
   * The variant of the input
   */
  variant: PropTypes.oneOf(['outline', 'filled', 'unstyled', 'flushed']),
  /**
   * The size of the input
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  /**
   * The minimum number of rows of the textarea
   */
  rows: PropTypes.number,
  /**
   * The maximum number of rows of the textarea
   */
  rowsMax: PropTypes.number,
  /**
   * The value of the textarea
   */
  defaultValue: PropTypes.string,
  /**
   * The on change function
   */
  onChange: PropTypes.func
}

TextArea.defaultProps = {
  variant: 'outline',
  size: 'md',
  rows: 3
}

export default TextArea
