import React, { useState } from 'react'
import Input from '../Input'
import { InputRightElement } from '../InputElement'
import InputGroup from '../InputGroup'
import { HiOutlineSearch } from 'react-icons/hi'
import { IoMdClose } from 'react-icons/io'

const Search = (props) => {
  const { onSearch, size, ...rest } = props
  const [value, setValue] = useState('')

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (onSearch) {
        onSearch(value)
      }
    }
  }

  return (
    <InputGroup size={size}>
      <Input
        placeholder='Search & Press Enter'
        onKeyDown={handleKeyDown}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        {...rest}
      />
      <InputRightElement>
        {value ? (
          <IoMdClose
            style={{ fontSize: '20px', cursor: 'pointer' }}
            onClick={() => {
              setValue('')
              onSearch('')
            }}
          />
        ) : (
          <HiOutlineSearch fontSize={17} />
        )}
      </InputRightElement>
    </InputGroup>
  )
}

export default Search
