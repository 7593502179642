import { addOpacity, darkenColor, isDarkColor } from '../theme/colors-utils'
import { useColorMode } from '../ColourModeProvider'
import { useTheme } from '@emotion/react'

const grayGhostStyle = {
  light: {
    color: 'inherit',
    _hover: {
      bg: 'blackAlpha.200'
    },
    _active: {
      bg: 'blackAlpha.300'
    }
  },
  dark: {
    color: 'inherit',
    _hover: {
      bg: 'whiteAlpha.200'
    },
    _active: {
      bg: 'whiteAlpha.300'
    }
  }
}

const ghostVariantProps = ({ color, colorMode, theme }) => {
  const colour = theme.colors[`${color}`]
  let result
  if (color === 'gray') {
    result = grayGhostStyle
  } else {
    result = {
      light: {
        color: `${color}`,
        bg: 'transparent',
        _hover: {
          bg: addOpacity(colour, 0.12),
          textDecoration: 'none'
        },
        _active: {
          bg: addOpacity(colour, 0.24)
        }
      },
      dark: {
        color: `${color}`,
        bg: 'transparent',
        _hover: {
          bg: addOpacity(colour, 0.12)
        },
        _active: {
          bg: addOpacity(colour, 0.24)
        }
      }
    }
  }

  return result[colorMode]
}

/// /////////////////////////////////////////////////////////

const outlineVariantProps = (props) => {
  const { color, colorMode, theme } = props
  const borderColor = { light: 'gray.300', dark: 'gray.600' }
  const colour = theme.colors[`${color}`]

  return {
    border: 'control',
    borderColor: color === 'gray' ? borderColor[colorMode] : `${colour}`,
    ...ghostVariantProps(props)
  }
}

/// /////////////////////////////////////////////////////////

const graySolidStyle = (theme) => ({
  light: {
    bg: theme.colors.gray[200],
    _hover: {
      bg: theme.colors.gray[300]
    },
    _active: {
      bg: theme.colors.gray[400]
    }
  },
  dark: {
    bg: 'whiteAlpha.200',
    _hover: {
      bg: 'whiteAlpha.300'
    },
    _active: {
      bg: theme.colors.gray[600]
    }
  }
})

const solidVariantProps = ({ color, colorMode, theme }) => {
  const colour = theme.colors[`${color}`]
  let style

  if (color === 'gray') {
    style = graySolidStyle(theme)
  } else {
    style = {
      light: {
        bg: colour,
        color: isDarkColor(colour) ? 'white' : 'blackAlpha.800',
        _hover: {
          bg: darkenColor(`${colour}`, 0.05)
        },
        _active: {
          bg: darkenColor(`${colour}`, 0.1)
        }
      },
      dark: {
        bg: `${color}`,
        color: isDarkColor(colour) ? 'white' : 'blackAlpha.800',
        _hover: {
          bg: darkenColor(`${colour}`, 0.05)
        },
        _active: {
          bg: darkenColor(`${colour}`, 0.1)
        }
      }
    }
  }

  return style[colorMode]
}

/// /////////////////////////////////////////////////////////

const linkVariantProps = ({ color, colorMode, theme }) => {
  const colour = theme.colors[`${color}`]
  const baseStyle = {
    p: 0,
    height: 'auto',
    lineHeight: 'normal',
    bg: 'transparent',
    _hover: {
      textDecoration: 'underline'
    }
  }

  let style

  if (color === 'gray') {
    style = {
      light: {
        color: 'global.text',

        _active: {
          color: 'primary'
        }
      },
      dark: {
        color: 'global.text',

        _active: {
          color: 'primary'
        }
      }
    }
  } else {
    style = {
      light: {
        bg: colour,
        color: colour,
        _active: {
          bg: darkenColor(`${colour}`, 0.1)
        }
      },
      dark: {
        bg: `${color}`,
        color: `${color}`,
        _active: {
          bg: darkenColor(`${colour}`, 0.1)
        }
      }
    }
  }

  return { ...style[colorMode], ...baseStyle }
}

/// /////////////////////////////////////////////////////////

const disabledProps = {
  _disabled: {
    opacity: '40%',
    cursor: 'not-allowed',
    boxShadow: 'none'
  }
}

/// /////////////////////////////////////////////////////////

const sizes = {
  lg: {
    height: 12,
    minWidth: 12,
    fontSize: 'lg',
    px: 6
  },
  md: {
    height: 10,
    minWidth: 10,
    fontSize: 'md',
    px: 4
  },
  sm: {
    height: 8,
    minWidth: 8,
    fontSize: 'sm',
    px: 3
  },
  xs: {
    height: 6,
    minWidth: 6,
    fontSize: 'xs',
    px: 2
  }
}

const sizeProps = ({ size }) => sizes[size]

/// /////////////////////////////////////////////////////////

const focusProps = {
  _focus: {
    boxShadow: 'buttonFocus'
  }
}

/// /////////////////////////////////////////////////////////

const unstyledStyle = {
  userSelect: 'inherit',
  bg: 'none',
  border: 0,
  color: 'inherit',
  display: 'inline',
  lineHeight: 'inherit',
  m: 0,
  p: 0,
  textAlign: 'inherit'
}

/// /////////////////////////////////////////////////////////

const variantProps = (props) => {
  switch (props.variant) {
    case 'solid':
      return solidVariantProps(props)
    case 'ghost':
      return ghostVariantProps(props)
    case 'link':
      return linkVariantProps(props)
    case 'outline':
      return outlineVariantProps(props)
    case 'unstyled':
      return unstyledStyle
    default:
      return {}
  }
}

/// /////////////////////////////////////////////////////////

const baseProps = (props) => {
  const textAlign = () => {
    switch (props.align) {
      case 'left':
        return 'flex-start'
      case 'center':
        return 'center'
      case 'right':
        return 'flex-end'
      default:
        return 'center'
    }
  }

  return {
    display: 'inline-flex',
    appearance: 'none',
    alignItems: 'center',
    justifyContent: textAlign(),
    transition: 'all 250ms',
    userSelect: 'none',
    position: 'relative',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    lineHeight: '1.2',
    outline: 'none',
    cursor: 'pointer'
  }
}

/// /////////////////////////////////////////////////////////

const useButtonStyle = (props) => {
  const { colorMode } = useColorMode()
  const theme = useTheme()

  const _props = { ...props, colorMode, theme }
  return {
    ...baseProps(props),
    ...sizeProps(_props),
    ...disabledProps,
    ...variantProps(_props),
    ...focusProps
  }
}

export default useButtonStyle
