import { useColorMode, useTheme } from '../ColourModeProvider'
import { get } from 'styled-system'

const outlinedStyle = ({
  theme,
  colorMode,
  focusBorderColor,
  errorBorderColor
}) => {
  const bg = theme.colors.inputs.background
  const borderColor = theme.colors.inputs.borderColour
  const hoverColor = { light: 'gray.300', dark: 'whiteAlpha.200' }

  /**
   * styled-system's get takes 3 args
   * - object or array to read from
   * - key to get
   * - fallback value
   */
  const _focusBorderColor = get(
    theme.colors,
    focusBorderColor,
    focusBorderColor // If color doesn't exist in theme, use it's raw value
  )
  const _errorBorderColor = get(
    theme.colors,
    errorBorderColor,
    errorBorderColor
  )

  return {
    ...readOnly,
    border: 'input',
    borderColor: borderColor,
    bg: bg,
    _hover: {
      borderColor: hoverColor[colorMode]
    },
    _disabled: {
      opacity: '0.4',
      cursor: 'not-allowed'
    },
    _focus: {
      zIndex: 1,
      borderColor: _focusBorderColor,
      boxShadow: 'inputFocus'
    },
    _invalid: {
      borderColor: _errorBorderColor,
      boxShadow: `0 0 0 1px ${_errorBorderColor}`
    }
  }
}

const readOnly = {
  _readOnly: {
    bg: 'transparent',
    boxShadow: 'none !important',
    userSelect: 'all'
  }
}

const filledStyle = ({
  theme,
  focusBorderColor,
  errorBorderColor,
  colorMode
}) => {
  const bg = { light: 'gray.100', dark: 'whiteAlpha.100' }
  const hoverColor = { light: 'gray.200', dark: 'whiteAlpha.100' }

  const _focusBorderColor = get(
    theme.colors,
    focusBorderColor,
    focusBorderColor
  )
  const _errorBorderColor = get(
    theme.colors,
    errorBorderColor,
    errorBorderColor
  )

  return {
    ...readOnly,
    border: 'input',
    borderColor: 'transparent',
    bg: bg[colorMode],
    _hover: {
      bg: hoverColor[colorMode]
    },
    _disabled: {
      opacity: '0.4',
      cursor: 'not-allowed'
    },
    _focus: {
      zIndex: 1,
      bg: 'transparent',
      borderColor: _focusBorderColor
    },
    _invalid: {
      borderColor: _errorBorderColor
    }
  }
}

const flushedStyle = ({ theme, focusBorderColor, errorBorderColor }) => {
  const _focusBorderColor = get(
    theme.colors,
    focusBorderColor,
    focusBorderColor
  )
  const _errorBorderColor = get(
    theme.colors,
    errorBorderColor,
    errorBorderColor
  )

  return {
    ...readOnly,
    borderBottom: 'input',

    rounded: 0,
    px: undefined,
    bg: 'transparent',
    _focus: {
      zIndex: 1,
      borderColor: _focusBorderColor
    },
    _invalid: {
      borderColor: _errorBorderColor
    }
  }
}

const unstyledStyle = {
  bg: 'transparent',
  px: undefined,
  height: undefined
}

const variantProps = (props) => {
  switch (props.variant) {
    case 'flushed':
      return flushedStyle(props)
    case 'unstyled':
      return unstyledStyle
    case 'filled':
      return filledStyle(props)
    case 'outline':
      return outlinedStyle(props)
    default:
      return {}
  }
}

const baseProps = {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  transition: 'all 0.2s',
  outline: 'none',
  appearance: 'none'
}

export const inputSizes = {
  lg: {
    fontSize: 'lg',
    px: 4,
    height: 12,
    rounded: 'inputBorderRadiusLg'
  },
  md: {
    fontSize: 'md',
    px: 2,
    height: 10,
    rounded: 'inputBorderRadiusMd'
  },
  sm: {
    fontSize: 'sm',
    px: 2,
    height: 8,
    rounded: 'inputBorderRadiusSm'
  }
}

const sizeProps = (props) => inputSizes[props.size]

const useInputStyle = (props) => {
  const theme = useTheme()
  const { colorMode } = useColorMode()

  const _props = { ...props, theme, colorMode }

  return {
    width: props.isFullWidth ? '100%' : undefined,
    ...baseProps,
    ...sizeProps(_props),
    ...variantProps(_props)
    // pb: "1px"
  }
}

export default useInputStyle
