import React, { useCallback } from 'react'
//import { useDisclosure } from 'react-use-disclosure';

export const useDisclosure = (defaultValue) => {
  const [isOpen, setIsOpen] = React.useState(defaultValue)

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const onToggle = React.useCallback(() => {
    const action = isOpen ? onClose : onOpen
    action()
  }, [isOpen, onOpen, onClose])

  return {
    isOpen: isOpen,
    close: onClose,
    open: onOpen,
    toggle: onToggle
  }
}
