/** @jsx jsx */
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import {
  createShouldForwardProp,
  props
} from '@styled-system/should-forward-prop'
import {
  layout,
  background,
  border,
  color,
  flexbox,
  grid,
  position,
  shadow,
  space,
  typography,
  compose
} from 'styled-system'
import extraConfig from './config'

export const truncate = (props) => {
  if (props.isTruncated) {
    return {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  }
}

export const systemProps = compose(
  layout,
  color,
  space,
  background,
  border,
  grid,
  position,
  shadow,
  typography,
  flexbox,
  extraConfig
)

const shouldForwardProp = createShouldForwardProp([
  ...props,
  'd',
  'textDecoration',
  'pointerEvents',
  'visibility',
  'transform',
  'cursor',
  'fill',
  'stroke',
  'content'
])

/**
 * htmlWidth and htmlHeight is used in the <Image />
 * component to support the native `width` and `height` attributes
 */
const nativeHTMLPropAlias = ['htmlWidth', 'htmlHeight']

const Box = styled('div', {
  shouldForwardProp: (prop) => {
    if (nativeHTMLPropAlias.includes(prop)) {
      return true
    } else {
      return shouldForwardProp(prop)
    }
  }
})(truncate, systemProps)

Box.displayName = 'Box'

Box.propTypes = {
  /**
   * Optional click handler
   */
  onClick: PropTypes.func
}

export default Box
