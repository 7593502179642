import PseudoBox from '../PseudoBox'
import styled from '@emotion/styled'

export const Arrow = styled(PseudoBox)`
  width: 12px;
  height: 12px;
  z-index: -1;
`

export const DropdownWrapper = styled(PseudoBox)`
  &[data-popper-placement^='bottom'] {
    ${Arrow} {
      top: -6px;
    }
  }
  &[data-popper-placement^='top'] {
    ${Arrow} {
      bottom: -6px;
    }
  }
  &[data-popper-placement^='right'] {
    ${Arrow} {
      left: -6px;
    }
  }
  &[data-popper-placement^='left'] {
    ${Arrow} {
      left: -6px;
    }
  }
`
