/** @jsx jsx */

import { setLightness } from 'polished';
import { useColorMode, useTheme } from '../ColourModeProvider';

const solid = ({ color }) => {
    return {
        light: { bg: `${color}`, color: 'white' },
        dark: { bg: `${color}`, color: 'white' },
    };
};

/* eslint-disable react-hooks/rules-of-hooks */
const subtle = ({ color }) => {
    const theme = useTheme();
    return {
        light: {
            bg: setLightness(0.95, theme.colors[color]),
            color: setLightness(0.4, theme.colors[color]),
        },
        dark: {
            bg: setLightness(0.9, theme.colors[color]),
            color: setLightness(0.3, theme.colors[color]),
        },
    };
};

const plain = () => {
    return {
        light: {
            bg: 'blackAlpha.800',
            color: 'white',
        },
        dark: {
            bg: 'whiteAlpha.800',
            color: 'black',
        },
    };
};

const statusStyleProps = (props) => {
    switch (props.variant) {
        case 'solid':
            return solid(props);
        case 'subtle':
            return subtle(props);
        case 'plain':
            return plain(props);
        default:
            return {};
    }
};

const baseProps = {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    px: 4,
    py: 3,
    borderRadius: 'alert',
};

const useAlertStyle = (props) => {
    const { colorMode } = useColorMode();
    const theme = useTheme();
    const _props = { ...props, theme };

    return {
        ...baseProps,
        ...statusStyleProps(_props)[colorMode],
    };
};

export const useAlertIconStyle = ({ variant, color }) => {
    const { colorMode } = useColorMode();
    const theme = useTheme();
    let result = {
        light: {
            color:
                variant === 'solid'
                    ? 'white'
                    : setLightness(0.4, theme.colors[color]),
        },
        dark: {
            color: variant === 'solid' ? 'white' : theme.colors[color],
        },
    };
    return result[colorMode];
};

export default useAlertStyle;
