import React from 'react';
import {
    Accordion as UIAccordion,
    AccordionItem as UIAccordionItem,
    AccordionButton as UIAccordionButton,
    useAccordionItemContext,
} from '@reach/accordion';
import PropTypes from 'prop-types';
import Box from '../Box';
import Button from '../Button';
import Flex from '../Flex';
import { RiArrowRightSLine, RiArrowDownSLine } from 'react-icons/ri';
import AnimateHeight from 'react-animate-height';

const useAccordionStyle = (props) => {
    return {
        mb: 2,
        cursor: 'pointer',
        w: '100%',
        ml: 0,
        display: 'flex',
        justifyContent: 'flex-start',
    };
};

const Accordion = React.forwardRef(
    (
        { children, collapsible, multiple, defaultIndex, readOnly, onChange },
        ref
    ) => {
        return (
            <UIAccordion
                ref={ref}
                collapsible={collapsible}
                multiple={multiple}
                defaultIndex={defaultIndex}
                readOnly={readOnly}
                onChange={onChange}
            >
                {children}
            </UIAccordion>
        );
    }
);

Accordion.defaultProps = {
    collapsible: false,
    multiple: false,
    defaultIndex: null,
    readOnly: false,
    showArrow: true,
    arrowAlign: 'left',
};

Accordion.propTypes = {
    /**
     * Optional click handler
     */
    onClick: PropTypes.func,
    /**
     * Dictate that an accordion should allow all panels to be collapsed simultaneously. By default, one panel must be in an open state at all times.
     */
    collapsible: PropTypes.bool,
    /**
     * Dictates that any number of panels may be open at the same time
     */
    multiple: PropTypes.bool,
    /**
     * Set the default open item
     */
    defaultIndex: PropTypes.number,
    /**
     * Dictates if Accordion is read only
     */
    readOnly: PropTypes.bool,
};

const AccordionPanel = React.forwardRef(({ children, ...rest }, ref) => {
    const context = useAccordionItemContext();
    const [height, setHeight] = React.useState(0);

    React.useEffect(() => {
        if (context.isExpanded) {
            setHeight('auto');
        } else {
            setHeight(0);
        }
    }, [context.isExpanded]);

    return (
        <AnimateHeight duration={300} height={height}>
            <Box fontSize='md' {...rest} ref={ref}>
                {children}
            </Box>
        </AnimateHeight>
    );
});

AccordionPanel.defaultProps = {
    px: 5,
    pb: 3,
    mb: 0,
};

const AccordionButton = React.forwardRef(
    ({ children, arrowAlign, showArrow, size, ...rest }, ref) => {
        const context = useAccordionItemContext();
        return (
            <UIAccordionButton
                as={Button}
                variant='ghost'
                ref={ref}
                size={size}
                {...useAccordionStyle()}
                {...rest}
                {...context}
                rightIcon={
                    arrowAlign === 'right' && showArrow ? (
                        context.isExpanded ? (
                            <RiArrowDownSLine fontSize={20} />
                        ) : (
                            <RiArrowRightSLine fontSize={20} />
                        )
                    ) : null
                }
                leftIcon={
                    arrowAlign === 'left' && showArrow ? (
                        context.isExpanded ? (
                            <RiArrowDownSLine fontSize={20} />
                        ) : (
                            <RiArrowRightSLine fontSize={20} />
                        )
                    ) : null
                }
            >
                <Flex align='center' w='100%'>
                    {children}
                </Flex>
            </UIAccordionButton>
        );
    }
);

AccordionButton.propTypes = {
    showArrow: PropTypes.bool,
    /**
     * Which side should the arrow pointer be on?
     */
    arrowAlign: PropTypes.oneOf(['left', 'right']),
};

AccordionButton.defaultProps = {
    showArrow: true,
    arrowAlign: 'left',
};

const AccordionItem = React.forwardRef(({ children }, ref) => {
    return <UIAccordionItem ref={ref}>{children}</UIAccordionItem>;
});

export { Accordion, AccordionItem, AccordionPanel, AccordionButton };
