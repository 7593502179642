/** @jsx jsx */
import { jsx } from '@emotion/react'
import { forwardRef } from 'react'
import { useColorMode } from '../ColourModeProvider'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

const ToolTipComponent = forwardRef(
  ({ children, title, placement, ...props }, ref) => {
    const { colorMode } = useColorMode()

    const useStyles = makeStyles((theme) => ({
      arrow: {
        color: 'transparent'
      },
      tooltip: {
        backgroundColor:
          colorMode === 'light'
            ? 'rgba(0, 0, 0, 0.8)'
            : 'rgba(255, 255, 255, 0.8)',
        fontSize: 13,
        fontWeight: 'normal',
        borderRadius: '6px',
        color:
          colorMode === 'dark'
            ? 'rgba(0, 0, 0, 0.95)'
            : 'rgba(255, 255, 255, 0.95)'
      }
    }))
    const classes = useStyles()

    return (
      <Tooltip
        title={title}
        arrow={true}
        classes={classes}
        placement={placement}
      >
        <div ref={ref}>{children}</div>
      </Tooltip>
    )
  }
)

ToolTipComponent.displayName = 'Tooltip'

ToolTipComponent.propTypes = {
  title: PropTypes.string.isRequired
}

export default ToolTipComponent
