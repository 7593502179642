import React, { forwardRef } from 'react';
import Button from '../Button';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const IconButton = forwardRef((props, ref) => {
    const { icon, children, isRound, size, ...rest } = props;

    /**
     * Passing the icon as prop or children should work
     */
    const iconElement = icon || children;

    return (
        <Button
            padding={0}
            borderRadius={isRound ? 'full' : size}
            ref={ref}
            size={size}
            {...rest}
        >
            <Icon fontSize={size}>{iconElement}</Icon>
        </Button>
    );
});

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
    /**
     * Toggles circular button
     */
    isRound: PropTypes.bool,
};

export default IconButton;
