import { base } from '../palette'

export default {
  light: {
    bg: '#f4f4fc',
    elementBg: 'white',
    elementBgAlt: 'white',
    text: base.gray[800],
    borderColour: base.gray[200]
  },
  dark: {
    bg: '#0C0C0E',
    elementBg: '#0c0c0e',
    elementBgAlt: '#2A2A30',
    text: base.gray[50],
    borderColour: base.whiteAlpha[200]
  }
}
