import { useColorMode } from '../ColourModeProvider'

const tabUnstyledStyle = {
  border: 'none',
  outline: 'none'
}

const tabTabStyle = ({ isSelected, orientation }) => {
  let borderRadiusProps = {}

  if (orientation === 'horizontal') {
    borderRadiusProps = {
      borderTopLeftRadius: 'tabs',
      borderTopRightRadius: 'tabs'
    }
  }
  if (orientation === 'vertical') {
    borderRadiusProps = {
      borderRadius: 'tabs',
      width: '100%'
    }
  }

  return {
    ...borderRadiusProps,
    bg: isSelected ? 'tabs.background' : 'tabs.unSelectedBackground'
  }
}

const useSelectedStyle = ({ isSelected, selectedStyle, variant }) => {
  const style =
    selectedStyle ||
    (variant === 'underlined' && {
      borderBottomColor: 'primary',
      cursor: 'default'
    })

  if (isSelected) {
    return { ...style }
  }
}

const tabVariantProps = ({
  variant,
  isSelected,
  colorMode,
  orientation,
  size
}) => {
  switch (variant) {
    case 'unstyled':
      return tabUnstyledStyle
    case 'tab':
      return tabTabStyle({ isSelected, colorMode, orientation, size })

    default:
      return {}
  }
}

export const useTabStyle = (props) => {
  const { colorMode } = useColorMode()
  const { variant, isSelected, selectedStyle, size, orientation } = props
  return {
    ...useSelectedStyle({ isSelected, selectedStyle, variant }),
    ...tabVariantProps({
      variant,
      isSelected,
      colorMode,
      orientation,
      size
    }),
    display: 'flex',
    justifyContent: orientation === 'vertical' ? 'center' : 'center',
    w: '100%',
    h: 'inherit',
    flexShrink: 0,
    mr: 0,
    fontWeight: 'semibold',
    pb: '0px',
    mb: orientation === 'vertical' ? 1 : 0,
    ml: 0,
    borderBottom: variant === 'underlined' && '3px solid',
    borderBottomColor: 'transparent'
  }
}

export const useHoverStyle = (isSelected) => {
  return {
    bg: !isSelected && 'tabs.hoverBackground',
    borderRadius: 'inherit'
  }
}
