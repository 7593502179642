/** @jsx jsx */
import { jsx } from '@emotion/react'
import { cloneElement } from 'react'
import Box from '../Box'
import Input from '../Input'
import { inputSizes } from '../Input/styles'
import { InputLeftElement, InputRightElement } from '../InputElement'
import { InputLeftAddon, InputRightAddon } from '../InputAddon'
import { useTheme } from '../ColourModeProvider'
import { cleanChildren } from '../utils'
import { InputGroupContext } from './context'

const InputGroup = ({ children, size = 'md', variant, ...props }) => {
  const { sizes } = useTheme()
  const height = inputSizes[size] && inputSizes[size]['height']
  let pl = null
  let pr = null
  const validChildren = cleanChildren(children)

  const hasLeftAddon = () => {
    return validChildren.some((e) => e.type === InputLeftAddon)
  }
  const hasRightAddon = () => {
    return validChildren.some((e) => e.type === InputRightAddon)
  }

  return (
    <InputGroupContext.Provider value={{ size: size, variant: variant }}>
      <Box display='flex' position='relative' zIndex={0} {...props}>
        {validChildren.map((child) => {
          if (child.type === InputLeftElement) {
            pl = sizes[height]
          }
          if (child.type === InputRightElement) {
            pr = sizes[height]
          }
          if (child.type === Input) {
            return cloneElement(child, {
              ...props,
              size,
              pl: child.props.pl || pl,
              pr: child.props.pr || pr,
              roundedLeft: hasLeftAddon() && 0,
              roundedRight: hasRightAddon() && 0
            })
          }
          return cloneElement(child, { size })
        })}
      </Box>
    </InputGroupContext.Provider>
  )
}

export default InputGroup
