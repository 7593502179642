/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useContext, memo, useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalButton,
  ModalContent,
  ModalFooter,
  ModalContext
} from '../Modal'
import Box from '../Box'
import Icon from '../Icon'
import Button from '../Button'
import { TiInfoOutline } from 'react-icons/ti'
import PropTypes from 'prop-types'

const Footer = memo(
  ({ cancelText, okText, onConfirm, okButtonProps, cancelButtonProps }) => {
    const { close } = useContext(ModalContext)
    return (
      <ModalFooter showCloseIcon={false}>
        <Button
          variant='outline'
          variantColor='error'
          onClick={() => close()}
          mr='10px'
          {...cancelButtonProps}
        >
          {cancelText}
        </Button>
        <Button
          variantColor='primary'
          onClick={() => {
            onConfirm()
            close()
          }}
          {...okButtonProps}
        >
          {okText}
        </Button>
      </ModalFooter>
    )
  }
)

const PopConfirm = (props) => {
  const {
    children,
    title,
    onConfirm,
    okText,
    cancelText,
    okButtonProps,
    cancelButtonProps
  } = props

  const [isOpen, setIsOpen] = useState(false)

  return (
    <Modal size='sm' open={isOpen} zIndex={9999}>
      <ModalButton onClick={() => setIsOpen(true)}>{children}</ModalButton>

      <ModalContent>
        <ModalHeader>
          <Icon fontSize={26} mr='10px' color='warning'>
            <TiInfoOutline />
          </Icon>{' '}
          Confirmation
        </ModalHeader>
        <Box p={6}>{title}</Box>
        <Footer
          cancelText={cancelText}
          cancelButtonProps={cancelButtonProps}
          okButtonProps={okButtonProps}
          okText={okText}
          onConfirm={onConfirm}
          setIsOpen={setIsOpen}
        />
      </ModalContent>
    </Modal>
  )
}

PopConfirm.propTypes = {
  /**
   * The message for the confirmation
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * The text for the OK button
   */
  okText: PropTypes.string,
  /**
   * The text for the Cancel button
   */
  cancelText: PropTypes.string,
  /**
   * The props or overriding styles for the OK button
   */
  okButtonProps: PropTypes.object,
  /**
   * The props or overriding styles for the Cancel button
   */
  cancelButtonProps: PropTypes.object,
  /**
   * The function to action on confirm
   */
  onConfirm: PropTypes.func
}

PopConfirm.defaultProps = {
  okText: 'OK',
  cancelText: 'Cancel',
  title: 'Are you sure?'
}

export default PopConfirm
