import { base } from '../palette';

export default {
    light: {
        bg: base.gray[800],
        text: base.gray[50],
    },
    dark: {
        bg: base.gray[200],
        text: base.gray[800],
    },
};
