/** @jsx jsx */
import { forwardRef } from 'react'
import { jsx, keyframes } from '@emotion/react'
import PropTypes from 'prop-types'
import Box from '../Box'
import { useTheme, useColorMode } from '../ColourModeProvider'

const wave = keyframes`
    0% {
           transform: translateX(-100%);
        }
         60% {
           transform: translateX(100%);
         }
         100% {
           transform: translateX(100%);
         }
`

const pulse = keyframes`
0% {
       opacity: 1
     }
     50% {
       opacity: 0.4
     }
     100% {
       opacity: 1
     }
`

const variantProps = {
  text: {
    marginTop: 0,
    marginBottom: 0,
    height: 'auto',
    transformOrigin: '0 60%',
    transform: 'scale(1, 0.60)'
  },
  circle: {
    borderRadius: '50%'
  }
}

const useSkeletonStyles = ({ variant, width, height, colorMode, reverse }) => {
  return {
    minHeight: height ? height : '1.2em',
    display: 'block',
    bg:
      colorMode === 'light'
        ? reverse
          ? 'white'
          : 'gray.100'
        : reverse
        ? 'blackAlpha.700'
        : 'whiteAlpha.200',
    w: width ? width : '100%',
    ...variantProps[variant]
  }
}

const Skeleton = forwardRef((props, ref) => {
  const theme = useTheme()
  const { colorMode } = useColorMode()

  const animationProps = {
    wave: {
      position: 'relative',
      overflow: 'hidden',
      '&:after': {
        animation: `${wave} 1.6s linear 0.5s infinite`,
        background: `linear-gradient(90deg, transparent, ${
          colorMode === 'light'
            ? theme.colors.blackAlpha[50]
            : theme.colors.whiteAlpha[200]
        }, transparent)`,
        content: '""',
        position: 'absolute',
        transform: 'translateX(-100%)',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        zIndex: 5
      },
      '&:empty:before': {
        content: '"\\00a0"'
      }
    },
    pulse: {
      animation: `${pulse} 1.5s ease-in-out 0.5s infinite`
    }
  }

  const {
    animation,
    component,
    height = null,
    variant,
    reverse,
    width = null,
    ...other
  } = props

  return (
    <Box
      as={component}
      ref={ref}
      {...useSkeletonStyles({
        animation,
        variant,
        width,
        height,
        colorMode,
        reverse
      })}
      css={animationProps[animation]}
      {...other}
    />
  )
})

Skeleton.propTypes = {
  /**
   * The animation.
   * If `false` the animation effect is disabled.
   */
  animation: PropTypes.oneOf(['pulse', 'wave', false]),

  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * Height of the skeleton.
   * Useful when you don't want to adapt the skeleton to a text element but for instance a card.
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The type of content that will be rendered.
   */
  variant: PropTypes.oneOf(['text', 'rect', 'circle']),
  /**
   * Use if the skeleton goes onto a dark background.
   */
  reverse: PropTypes.bool,
  /**
   * Width of the skeleton.
   * Useful when the skeleton is inside an inline element with no width of its own.
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

Skeleton.defaultProps = {
  animation: 'wave',
  variant: 'text',
  component: 'span'
}

export default Skeleton
