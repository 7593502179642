import { base } from '../palette'

export default {
  light: {
    gray: base.gray[500]
  },
  dark: {
    background: base.gray[700],
    borderColour: base.gray[600]
  }
}
