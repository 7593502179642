import { base } from '../palette'

export default {
  light: {
    background: 'white',
    borderColour: base.gray[300],
    placeholderColour: base.gray[400]
  },
  dark: {
    background: base.whiteAlpha[100],
    borderColour: base.whiteAlpha[300],
    placeholderColour: base.whiteAlpha[400]
  }
}
