import { base } from '../palette'

export default {
  light: {
    borderColour: base.gray[300]
  },
  dark: {
    borderColour: base.whiteAlpha[200]
  }
}
