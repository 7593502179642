/** @jsx jsx */
import { jsx } from '@emotion/react'
import { forwardRef } from 'react'
import { useFormControl } from '../FormControl'

import Text from '../Text'
import Flex from '../Flex'
import Icon from '../Icon'
import { MdErrorOutline } from 'react-icons/md'

export const FormErrorMessage = forwardRef((props, ref) => {
  const formControl = useFormControl(props)

  return (
    <Flex align='center' mt={1}>
      <Icon color='error' mr='5px'>
        <MdErrorOutline />
      </Icon>
      <Text
        ref={ref}
        id={formControl.id ? `${formControl.id}-help-text` : null}
        lineHeight='normal'
        fontSize='xs'
        color='error'
        {...props}
      />
    </Flex>
  )
})

FormErrorMessage.displayName = 'FormErrorMessage'

export default FormErrorMessage
