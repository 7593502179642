/** @jsx jsx */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useFormControl } from '../FormControl'
import Flex from '../Flex'
import useInputStyle from './styles'
import Button from '../Button'
import { FiChevronDown } from 'react-icons/fi'
import Icon from '../Icon'
import styled from '@emotion/styled'

const StyledSelect = styled(Button)`
  option: {
    background-color: red !important;
    font-size: 50px;
  }
`

const Select = forwardRef((props, ref) => {
  const {
    size,
    variant,
    as,
    'aria-label': ariaLabel,
    'aria-describedby': ariaDescribedby,
    isReadOnly,
    isFullWidth,
    isDisabled,
    isInvalid,
    isRequired,
    autocomplete,
    children,
    onChange,
    onBlur,
    ...rest
  } = props

  const inputStyleProps = useInputStyle(props)
  const formControl = useFormControl(props)

  return (
    <Flex align='center' position='relative'>
      <StyledSelect
        ref={ref}
        as='select'
        readOnly={formControl.isReadOnly}
        aria-readonly={isReadOnly}
        disabled={formControl ? formControl.isDisabled : isDisabled}
        aria-label={ariaLabel}
        aria-invalid={formControl ? formControl.isInvalid : isInvalid}
        required={formControl ? formControl.isRequired : isRequired}
        aria-required={formControl ? formControl.isRequired : isRequired}
        aria-disabled={formControl ? formControl.isDisabled : isDisabled}
        aria-describedby={ariaDescribedby}
        autocomplete={formControl ? formControl.autocomplete : autocomplete}
        onChange={onChange}
        onBlur={onBlur}
        {...inputStyleProps}
        minWidth={'150px'}
        w='100%'
        {...rest}
      >
        {children}
      </StyledSelect>
      <Icon position='absolute' right='15px' fontSize={19} zIndex={500}>
        <FiChevronDown />
      </Icon>
    </Flex>
  )
})

Select.displayName = 'Select'

Select.defaultProps = {
  size: 'md',
  variant: 'outline'
}

Select.propTypes = {
  /**
   * The element type you wish to render
   */
  as: PropTypes.string,
  /**
   * The variant type of the button
   */
  variant: PropTypes.oneOf(['solid', 'outline']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  /**
   * Is disabled
   */
  isDisabled: PropTypes.bool,
  /**
   * Make button fill full width of container
   */
  isFullWidth: PropTypes.bool
}

export default Select
