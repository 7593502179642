import React, { createContext, forwardRef, useContext } from 'react'
import Box from '../Box'
import Flex from '../Flex'
import { useDisclosure } from '../hooks'
import { Dialog } from '@reach/dialog'
import PseudoBox from '../PseudoBox'
import IconButton from '../IconButton'
import Button from '../Button'
import { MdClose } from 'react-icons/md'
import PropTypes from 'prop-types'
import { Scale } from '../Transition'
import ScrollContainer from '../ScrollContainer'

const ModalContext = createContext()

const maxWidths = {
  lg: 1100,
  md: 800,
  sm: 400
}

const baseProps = ({ size, maxWidth }) => {
  return {
    position: 'relative',
    zIndex: 8999,
    borderRadius: 'modal',
    width: '100%',
    maxWidth: maxWidth ? maxWidth : maxWidths[size],
    boxShadow: 'modal',
    overflow: 'hidden'
  }
}

const ModalHeader = ({ children, ...props }) => {
  const { close, showCloseIcon, onClose } = useContext(ModalContext)
  return (
    <Box
      py={3}
      pl={5}
      pr={3}
      fontSize='20px'
      fontWeight='semibold'
      {...props}
      position='sticky'
      top={0}
      bg='global.elementBg'
    >
      <Flex align='center'>
        {children}
        {showCloseIcon && (
          <IconButton
            size='md'
            variant='ghost'
            ml='auto'
            onClick={() => {
              onClose && onClose()
              close()
            }}
            icon={<MdClose fontSize='20px' />}
          />
        )}
      </Flex>
    </Box>
  )
}

const ModalFooter = ({ children, showCloseIcon = true }) => {
  const { close, onClose } = useContext(ModalContext)
  return (
    <Box
      py={3}
      pl={5}
      pr={3}
      fontWeight='medium'
      position='sticky'
      bottom='0px'
      bg='global.elementBg'
    >
      <Flex justify='flex-end'>
        {children}
        {showCloseIcon && (
          <Button
            variant='outline'
            onClick={() => {
              onClose && onClose()
              close()
            }}
          >
            Close
          </Button>
        )}
      </Flex>
    </Box>
  )
}

ModalFooter.propTypes = {
  /**
   * Show or hide the close icon
   */
  showCloseIcon: PropTypes.bool
}

const ModalBody = ({ children, ...rest }) => {
  return (
    <Box w='100%' {...rest}>
      {children}
    </Box>
  )
}

const ModalContent = forwardRef(({ children }, ref) => {
  const {
    allowPinchZoom,
    size,
    close,
    isOpen,
    maxWidth,
    onClose,
    open
  } = useContext(ModalContext)

  return isOpen ? (
    <Dialog
      isOpen={isOpen ? isOpen : open}
      aria-label='Modal'
      onDismiss={() => {
        onClose()
        close()
      }}
      allowPinchZoom={allowPinchZoom}
      style={{
        outline: 'none',
        border: 'none',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 9801
      }}
    >
      <ScrollContainer>
        <Flex
          justify='center'
          align='flex-start'
          h='100%'
          w='100%'
          p='30px'
          ref={ref}
          data-modal={true}
          zIndex={9800}
        >
          <Scale>
            {(props) => (
              <Box
                bg='global.elementBg'
                {...baseProps({ size, maxWidth })}
                willChange='opacity, transform'
                style={props}
                my='auto'
                display='flex'
                flexDirection='column'
                justifyContent='stretch'
                zIndex={9800}
              >
                <Box w='100%' h='auto' minHeight='100%' overflowY='auto'>
                  {children}
                </Box>
              </Box>
            )}
          </Scale>
        </Flex>
      </ScrollContainer>

      <Box
        w='100vw'
        h='100%'
        bg='modalBackdrop'
        position='fixed'
        top={0}
        left={0}
        onClick={() => {
          onClose && onClose()
          close()
        }}
        zIndex={9700}
      ></Box>
    </Dialog>
  ) : null
})

const ModalButton = forwardRef(({ children, onClick, ...rest }, ref) => {
  const { toggle } = useContext(ModalContext)

  return (
    <PseudoBox
      data-modal={true}
      ref={ref}
      onClick={() => {
        onClick && onClick()
        toggle()
      }}
      {...rest}
    >
      {children}
    </PseudoBox>
  )
})

const Modal = React.forwardRef((props, ref) => {
  const {
    children,
    allowPinchZoom,
    size,
    maxWidth,
    showCloseIcon,
    open,
    onClose
  } = props
  const { isOpen, toggle, close } = useDisclosure(open ? open : false)

  return (
    <ModalContext.Provider
      value={{
        open,
        isOpen,
        toggle,
        close,
        allowPinchZoom,
        size,
        maxWidth,
        showCloseIcon,
        onClose
      }}
    >
      {children}
    </ModalContext.Provider>
  )
})

Modal.propTypes = {
  /**
   * The size of the modal.
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  /**
   * The maximum width of the modal (overwrites the size)
   */
  maxWidth: PropTypes.number,
  /**
   * Allow user to pinch screen to zoom
   */
  allowPinchZoom: PropTypes.bool,
  /**
   * Show the close icon in the modal header (if present)
   */
  showCloseIcon: PropTypes.bool,
  /**
   * An optional function to fire when the modal closes
   */
  onClose: PropTypes.func
}

Modal.defaultProps = {
  allowPinchZoom: true,
  showCloseIcon: true,
  size: 'md'
}

export {
  Modal,
  ModalHeader,
  ModalButton,
  ModalContent,
  ModalFooter,
  ModalContext,
  ModalBody
}
