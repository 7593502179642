/** @jsx jsx */
import { jsx, keyframes } from '@emotion/react'
import { forwardRef } from 'react'
import Box from '../Box'
import VisuallyHidden from '../VisuallyHidden'
import PropTypes from 'prop-types'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(300deg)
  }
  100% {
    transform: rotate(360deg);
  }
`

const sizes = {
  xs: '0.75rem',
  sm: '1rem',
  md: '1.5rem',
  lg: '2rem',
  xl: '3rem'
}

const Spinner = forwardRef(
  (
    {
      size = 'md',
      label = 'Loading...',
      thickness = '2px',
      speed = '0.45s',
      color,
      emptyColor = 'transparent',
      ...props
    },
    ref
  ) => {
    const _size = sizes[size] || size

    return (
      <Box
        ref={ref}
        display='inline-block'
        borderWidth={thickness}
        borderColor='currentColor'
        borderBottomColor={emptyColor}
        borderLeftColor={emptyColor}
        borderStyle='solid'
        rounded='full'
        color={color}
        animation={`${spin} ${speed} linear infinite`}
        size={_size}
        {...props}
      >
        {label && <VisuallyHidden>{label}</VisuallyHidden>}
      </Box>
    )
  }
)

Spinner.displayName = 'Spinner'

Spinner.propTypes = {
  /**
   * The size of the spinner
   */
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  /**
   * The thickness of the spinner
   */
  thickness: PropTypes.string,
  /**
   * The speed of the spinner
   */
  speed: PropTypes.string,
  /**
   * The color of the spinner
   */
  color: PropTypes.string,
  /**
   * The color of the empty areas in the spinner.
   */
  emptyColor: PropTypes.string
}

export default Spinner
